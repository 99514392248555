<!--设备模块--数据输出页面-->
<template>
  <div
    id="loggingData"
    v-loading="loading"
    element-loading-text="加载中...."
    ref="loggingData"
  >
    <div class="inquire">      
      <a-form-model :model="form" @submit="handleSubmit">
        <div class="form_row">
          <div class="form_col">
            <a-form-model-item :label="$t('dataoutput.a1')">
              <a-select style="width: 200px" v-model="form.pointGrId" @change="handleChange">
                <a-select-option
                  v-for="item in selectedItems"                  
                  :value="item.no"
                  :key="item.no"     
                  :title="item.text"                         
                >{{item.text}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </div>  
          <div class="form_col">
            <a-form-model-item v-if="flag==1" :label="$t('dataoutput.a2')">
              <a-select style="width: 200px" v-model="form.pointIdAndNo" :disabled="form.pointGrId===''">
                <a-select-option                  
                  v-for="item in signalItems"
                  :value="item.no"
                  :key="item.no"
                  :title="item.text"
                >{{item.text}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </div> 
          <div class="form_col">
            <a-form-model-item :label="$t('dataoutput.a3')" prop="date1">
              <!-- show-time -->
              <a-date-picker
                v-model="form.date1"
                :disabled-date="disabledStartDate"
                :allowClear="false"
                format="YYYY-MM-DD"
                @openChange="handleStartOpenChange"
                @change="handleCalendarChange"
              />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item :label="$t('dataoutput.a4')" prop="date2">
              <!-- show-time -->
              <a-date-picker
                v-model="form.date2"
                :disabled-date="disabledEndDate"
                :allowClear="false"
                format="YYYY-MM-DD"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-model-item>
          </div>      
          <div class="form_col">
            <a-form-model-item>
              <a-button type="primary" style="width: 120px" html-type="submit">{{$t('dataoutput.a5')}}</a-button>
            </a-form-model-item>
          </div>   
        </div>      
      </a-form-model>
    </div>   
    <div class="main">     
      <a-tabs default-active-key="1" @change="tabChange">
        <a-tab-pane key="1" :tab="$t('dataoutput.a6')">  
            <my-tabletitle>{{$t('dataoutput.a6')}}</my-tabletitle>
            <a-table
              :row-selection="allrowSelection"
              :columns="columns"
              :dataSource="records"
              :scroll="{ x: 1300 }"
              :pagination="pagination"
            >
            </a-table>           
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('dataoutput.a7')" force-render>
          <div>
            <a-radio-group name="radioGroup" v-model="flag" @change="flagChange">
              <a-radio :value="1">{{$t('dataoutput.a8')}}</a-radio>
              <a-radio :value="2">{{$t('dataoutput.a9')}}</a-radio>
            </a-radio-group>
            <a-button type="primary" :loading="isShow" :disabled="isShow" style="width: 120px" @click="createFile">{{$t('dataoutput.a10')}}</a-button>
          </div> 
          <div>
            <a-divider />            
              <div v-show="isShow" >正在生成文件......</div>
              <a-table 
              :columns="columnsFile" 
              :data-source="fileInfos"   
              :rowKey="(record) => record.no"  
              >              
                <span slot="opea" slot-scope="text,record">                
                  <!-- <a href="record.realPath" title="点击下载" download="record.fileName">{{record.fileName}}</a> -->
                  <a-tooltip title="点击下载">
                    <a-button value="record.key" type="primary" icon="download" @click="onDownloadClick(record)"></a-button>
                  </a-tooltip>                
                </span>
              </a-table>         
            <a-divider />
            <div>{{$t('dataoutput.a12')}}</div>
            <div>{{$t('dataoutput.a13')}}</div> 
          </div>     
        </a-tab-pane>
      </a-tabs> 
    </div> 
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import { getEnergyTrendLogList,downloadEnergyTrendLogList,downloadEnergyTrendLogFile,getEnergyTrendLogFileList } from "../../api/energy";
import moment from "moment";
import {updateOverRunTime2} from "../../api/door";
import { downloadUrl } from "../../utils/utils";

// import Template from "../doorDetails/components/template.vue"; 

export default {    
  data() {
    return {
      loading:false,
      form: {
        pointGrId: "",
        pointIdAndNo: "",
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
      },
      fileInfo:{},  //文件信息
      fileInfos:[], //文件信息列表
      fileInfoTemp:{},//临时文件信息
      key:"",
      type: this.$route.query.type,
      buttonloading: false,
      startDate:{},
      endOpen: false,
      selectedItems: [],
      signalItems: [],
      records:[],    
      flag:1, 
      count: 1, //递归次数
      time:5000,//延迟时间（单位毫秒）
      isShow:false,
      selectedRowKeys:undefined,
      pagination: {
        total: 0, //数据总数
        pageSize: 5, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => `${total + this.$t("dataoutput.a14")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
      },
      columnsFile: [
        {
          title: 'No.',
          dataIndex: "no",
          width: 80,
          fixed: "left",      
        },
        {
          title: this.$t('dataoutput.a11'),
          dataIndex: 'fileName',
          scopedSlots: { customRender: 'fileName' },
        },  
        {
          title: this.$t('visitorRecord.a7'),
          dataIndex: 'opea',
          width: 80,
          scopedSlots: { customRender: 'opea' },
        },   
      ],
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          ellipsis: true,
          width: 70,
          fixed: "left",
        },
        {
          title: this.$t('dataoutput.b1'),
          dataIndex: "pointGrId",
          width: 150,
          ellipsis: true,
        },
        {
          title: this.$t('dataoutput.b2'),
          dataIndex: "pointGrName",
          width: 150,
          ellipsis: true,
        },
        {
          title: this.$t('dataoutput.b3'),
          dataIndex: "pointId",
          width: 130,
          ellipsis: true,
        },
        {
          title: this.$t('dataoutput.b4'),
          dataIndex: "pointName",
          width: 130,
          ellipsis: true,
        },
        {
          title: this.$t('dataoutput.b5'),
          dataIndex: "pointNo",
          width: 130,
          ellipsis: true,
        },
        {
          title: this.$t('dataoutput.b6'),
          dataIndex: "presentValue",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('dataoutput.b7'),
          dataIndex: "engineeringUnits",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('dataoutput.b8'),
          dataIndex: "date",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('dataoutput.b9'),
          dataIndex: "time",
          width: 100,
          ellipsis: true,
        },
      ],
      activeKey:1,
    };
  },
  // created(){
  //   this.getData();
  // },
  // mounted() {        
  //   this.getData();
  // },
  activated(){
    this.selectedItems=[];  
    this.selectedItems1=[];
    this.selectedItems2=[];  
    this.fileInfos=[];
    this.form = {
        pointGrId: "",
        pointIdAndNo: "",
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
      };
    this.records=[];
    this.getData();
  },
  watch: {     
    
  },
  computed: {    
    allrowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.addList = selectedRows;
        },
      };
    },
    // loading: function () {
    //     return false;
    // },
  },
  methods: {
    flagChange(obj){
      console.log('flagChange',obj,obj.target.value==1);
      if(obj.target.value==1){
          this.selectedItems = this.selectedItems1;
          this.form.pointGrId  = '';
          this.form.pointIdAndNo = '';
      }else{
          console.log('flagChange',this.selectedItems2);
          this.selectedItems = this.selectedItems2;
          this.form.pointGrId  = this.selectedItems[0].no;
          this.form.pointIdAndNo = '';
      }
    },
    tabChange(activeKey){
        this.activeKey = activeKey;
        console.log('tabChange',this.activeKey);
        if(activeKey==1){
          this.selectedItems = this.selectedItems1;
        }
    },
    onDownloadClick(record){
      console.log("onDownloadClick:",record); 
      this.downloadLogFile(record.realPath,record.fileName);
    },
    //下载文件
    downloadLogFile(realPath,fileName){     
      let par = {        
          action: 4,//下载文件
          star: moment(this.form.date1).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
          end: moment(this.form.date2).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          realPath:realPath,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
      };
      this.loading = true;
      downloadEnergyTrendLogFile(par)
      .then((res) => {
        let { data } = res;              
        console.log("downloadEnergyTrendLogFile:",data);  
        downloadUrl(res,fileName);   
        this.loading = false;               
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },
    //生成文件，供下载
    createFile(){
      
      this.isShow = true; 
      this.createTrendLogFile();
      console.log('createTrendLogFile watchDownload: ', this.key);  
      this.sleep(this.time).then(()=>{
        this.watchDownload();
        this.count++;
        console.log("getEnergyTrendLogFileList watchDownload0");
      });   
      // console.log('Received values of form: ', this.form);       
    },
    //查询按钮响应
    handleSubmit(e) {
      e.preventDefault();
      if(this.activeKey==2){
        this.getEnergyTrendLogFileList();
      }else{
        this.getTrendLogList();
      }
      // this.updateOverRunTime();
      // console.log('Received values of form: ', this.form);      
    },
    //设备选择更改响应
    handleChange(value) {
      console.log("getEnergyTrendLogList selectedItem:",value );
      this.signalItems = [];
      this.form.pointIdAndNo = '';
      if(value===''){
          
      }else{

        for(let i=0;i<this.selectedItems.length;i++){
          if(this.selectedItems[i].no == value){
            this.signalItems =this.selectedItems[i].children;
            break;
          }
        }
      }
      console.log("getEnergyTrendLogList signalItems:",this.signalItems );
      // this.getEnergyTrendLogFileList();
    },
    //查询已存在文件
    getEnergyTrendLogFileList(){
      let par = {        
          action: 1,//查询获取数据       
          star: moment(this.form.date1).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(this.form.date2).format("YYYY-MM-DD HH:mm:ss"),
          selDevice: this.form.pointGrId,
          selSignal: this.form.pointIdAndNo,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
      };
      this.loading =true;
      getEnergyTrendLogFileList(par)
      .then((res) => {
        let { data } = res;              
        this.fileInfos = data.files;
        for(let i=0;i<this.fileInfos.length;i++){
          this.fileInfos[i].no = i+1;
          if(this.fileInfos[i].key==null)
            this.fileInfos[i].key="";
        }
        console.log("getEnergyTrendLogFileList fileInfos:",this.fileInfos);    
        this.loading =false;               
      })
      .catch((err) => {
        console.log(err); 
        this.loading =false;               
      });
    },    
    // 获取当前已注册的设备信号列表
    getData() {
      let par = {        
          action: 0,//加载页面数据     
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          tenantId: 0,
          logType:1,
      };
      this.loading = true;
      getEnergyTrendLogList(par)
      .then((res) => {
        let { data } = res;            
        this.selectedItems=data.filterData;  
        this.selectedItems1=data.filterData;
        this.selectedItems2=new Array();  
        for(let i=0;i<this.selectedItems.length;i++){
          if(this.selectedItems[i].no == ''){
            continue;
          }
          this.selectedItems2.push(this.selectedItems[i]);
        }
        this.loading = false;
        console.log("getEnergyTrendLogList filterData:",this.selectedItems);                   
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });           
    },
    updateOverRunTime() {      
      let par = {        
          hour: 11,
          minute: 23,
          mode: 0,
          rootId: 102,
          siteId: 104,
      };
      console.log("updateOverRunTime2 par:",par);
      //查询接口函数
      updateOverRunTime2(par)
      .then((res) => {
        let { data } = res;                
        console.log("updateOverRunTime2 res:",data);                   
      })
      .catch((err) => {
        console.log(err);
      });
    },

    // 查询符合条件的数据
    getTrendLogList() {
      console.log("getEnergyTrendLogList date:",moment(this.form.date1).format("YYYY-MM-DD HH:mm:ss"));
      let par = {        
          action: 1,//查询获取数据       
          star: moment(this.form.date1).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(this.form.date2).format("YYYY-MM-DD HH:mm:ss"),
          selDevice: this.form.pointGrId,
          selSignal: this.form.pointIdAndNo,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
      };
       this.loading = true;
      //查询接口函数
      getEnergyTrendLogList(par)
      .then((res) => {
        let { data } = res;   
        console.log("getEnergyTrendLogList data:",data); 
        if(data!=null) {       
          this.records=data.data;          
          if(this.records !=null){
            for (let i = 0; i < this.records.length; i++) {
              this.records[i].key = i + 1;
            } 
          }  
        }
         this.loading = false;                  
      })
      .catch((err) => {
        console.log(err);
         this.loading = false;
      });
    },   
    //监听函数，监听服务端文件生成进度
    watchDownload(){
      //监听次数（超过一分钟不再监听）
      if(this.count*this.time>60000){
        this.count=1;
        return;
      }      

      if(this.key !=""){
        let par = {        
            action: 3,//监听
            key:this.key,
            star: moment(this.form.date1).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
            end: moment(this.form.date2).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            selDevice: this.form.pointGrId,
            selSignal: this.form.pointIdAndNo,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,         
        };
        // console.log("getEnergyTrendLogFileList begin:",par);
        getEnergyTrendLogFileList(par)
        .then((res) => {
          let { data } = res; 
          console.log("getEnergyTrendLogFileList watchDownload data:",data);        
          if(data!=null && data.result!=null&&data.result.key.length>1){
            this.fileInfoTemp = data.result;
            this.fileInfos.length=0;
            if(this.fileInfoTemp.fileLoadTag=="finish"&&this.fileInfos!=null){
              console.log("getEnergyTrendLogFileList create success:",this.fileInfo);
              this.fileInfo.no = 1;
              this.fileInfo.key = this.fileInfoTemp.key;
              this.fileInfo.realPath = this.fileInfo.realPath;
              this.fileInfos.push(this.fileInfo);
            }
            this.isShow = false;
            this.getEnergyTrendLogFileList();
          }  
          else{
            this.sleep(this.time).then(()=>{
              this.watchDownload();
              this.count++;
              console.log("getEnergyTrendLogFileList watchDownload1");
            })
          }                                    
        })
        .catch((err) => {
          this.sleep(this.time).then(()=>{
              this.watchDownload();
              this.count++;
              console.log("getEnergyTrendLogFileList watchDownload2");
            })
          console.log(err);
        });
      }
    },

    sleep(ms) {
      return new Promise(resolve => 
        setTimeout(resolve, ms)
       )
    },

    // 发送下载日志文件指令，返回文件key，文件名等信息
    createTrendLogFile() {
      console.log("downloadTrendLogList date:",moment(this.form.date1).format("YYYY-MM-DD HH:mm:ss"));
      // let selDevice = this.form.pointGrId===''?'ALL':this.form.pointGrId;
      // let selSignal = this.form.pointIdAndNo===''?'ALL':this.form.pointIdAndNo;
      let par = {        
          action: 2,//生成文件
          column: this.flag==2?"merge":"", 
          star: moment(this.form.date1).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
          end: moment(this.form.date2).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          selDevice: this.form.pointGrId,
          selSignal: this.form.pointIdAndNo,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,         
      };
      getEnergyTrendLogFileList(par)
      .then((res) => {
        let { data } = res;  
        console.log("getEnergyTrendLogFileList data:",data);        
        if(data!=null)   {
          this.fileInfo = data.result;
          if(this.fileInfo!=null)
            this.key=data.result.key;  
        }                
        console.log("getEnergyTrendLogFileList key:",this.key);                       
      })
      .catch((err) => {
        console.log(err);
      });
    }, 
    // 初始日期/时间的日期不可选范围
    disabledStartDate(startValue) {
      const endValue = this.form.date2;
      if (!startValue || !endValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        startValue >= moment().endOf()
      );
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startValue = this.form.date1;
      let startDate = this.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return (
        startValue.valueOf() >= endValue.valueOf() ||
        endValue >= moment().endOf() ||
        endValue >= moment(new Date(startDate)).add(1, "months")
      );
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      this.startDate = moment(value._d).format("YYYY-MM-DD HH:mm:ss");
      this.form.date2 = null;
    },
    // 初始日期/时间选择弹出日历和关闭日历的回调
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    // 终止日期/时间选择弹出日历和关闭日历的回调
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#loggingData {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;
}
.header {
  height: 60px;
  padding: 10px 20px 0px 20px;
}
.inquire {
  height: 100px;
  padding: 0px 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
  white-space: nowrap;
}
.main {
  width: 100%;
  height: calc(100% - 113px);
  padding: 10px 20px;
  overflow: auto;
}
.main-all-vessel {
  overflow: auto;
}
.main-button-vessel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  overflow: auto;
}
.main-button-vessel button {
  margin: 0px 10px;
}
.main-register-vessel {
  overflow: hidden;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
.name {
  font-size: 15px;
}
.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  overflow: hidden;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
}
.footer button {
  margin-left: 10px;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.margin {
  margin-right: 10px;
}
p {
  margin-bottom: 10px;
}
</style>